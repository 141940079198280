import moment from "moment";
import Pill from "../common/Pill";

const ReminderPill = ({ time }) => {
  const reminderTime = moment.unix(time);
  const timeNowUnix = moment().unix();
  if (time == null) {
    return (
      <Pill bg="bg-gray-100" text="text-gray-600">
        No reminders set
      </Pill>
    );
  }
  if (time < timeNowUnix) {
    return (
      <Pill bg="bg-red-100" text="text-red-600">
        🔔 {reminderTime.fromNow()}
      </Pill>
    );
  } else {
    return (
      <Pill bg="bg-green-100" text="text-green-600">
        🔔 {reminderTime.fromNow()}
      </Pill>
    );
  }
};
export default ReminderPill;
