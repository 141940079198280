import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Topbar from "./components/common/Topbar";
import TaskListPage from "./components/tasks/TaskListPage";
import LoginPage from "./components/auth/LoginPage";
import TaskViewPage from "./components/tasks/taskview/TaskViewPage";
import NewTaskViewPage from "./components/tasks/newtaskview/NewTaskViewPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <TaskListPage />,
    },
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/new",
        element: <NewTaskViewPage />
    },
    {
        path: "/:taskId",
        element: <TaskViewPage />
    }
]);

function App() {

    return (
        <div className="h-screen flex flex-col">
            <Topbar title="Kwik Tasks" />
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
