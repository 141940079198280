import { useState } from "react"
import moment from "moment";
import TaskViewItem from "../taskview/TaskViewItem"
import TaskViewDescriptionInput from "../taskview/TaskViewDescriptionInput"
import Pill from "../../common/Pill"
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CREATE_TASK } from "../../../data/tasks";
import { useEffect } from "react";
import Footer from "../../common/Footer";

const NewTaskViewPage = () => {
    const navigate = useNavigate();
    const [createTask, { data, loading }] = useMutation(CREATE_TASK)

    const [newTask, setNewTask] = useState({
        title: "",
        description: "",
        reminderTime: null
    })

    useEffect(() => {
        if (data && 'id' in data.createTask) {
            navigate("/")
            window.location.reload()
        }
    }, [data, navigate])

    const handleTitleChange = e => {
        setNewTask({ ...newTask, title: e.target.value })
    }

    const handleDescriptionChange = e => {
        setNewTask({ ...newTask, description: e.target.value })
    }

    const saveTask = () => {
        createTask({ variables: { ...newTask } })
    }

    return (
        <>
            <div className="flex-1 overflow-y-auto">
                <div className="p-4 rounded-t border-b">
                    <input
                        className='w-full text-lg font-medium'
                        placeholder="Enter task title"
                        type="text"
                        value={newTask.title}
                        onChange={handleTitleChange}
                    />
                </div>
                <div className="grow py-2 px-4 overflow-auto">
                    <TaskViewItem>
                        <TaskViewDescriptionInput
                            placeholder="Enter task description"
                            value={newTask.description}
                            onChange={handleDescriptionChange}
                        />
                    </TaskViewItem>

                    <TaskViewItem title="Reminder Time">
                        <Pill bg='bg-gray-100' text='text-gray-600'>
                            {newTask.reminderTime ? moment.unix(newTask.reminderTime).format("ddd, D MMM YYYY, h:mm a") : 'No reminders set'}
                        </Pill>
                    </TaskViewItem>
                </div>
            </div>

            <Footer>
                <button
                    type="button"
                    className="text-gray-600 bg-gray-200 hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() => navigate("/")}
                >
                    Back to Tasks
                </button>
                <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={saveTask}
                    disabled={loading}
                >
                    Save
                </button>

            </Footer>
        </>
    )
}

export default NewTaskViewPage