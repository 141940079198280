import { useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import Spinner from "../common/Spinner"
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../data/auth";


const LoginPage = () => {
    const navigate = useNavigate();

    const [login, { data, loading }] = useMutation(LOGIN)
    const [helpMessage, setHelpMessage] = useState("")

    useEffect(() => {
        if (data) {
            const { login } = data
            if (login == null) {
                setHelpMessage("Invalid credentials")
            } else {
                localStorage.setItem("jwt", login.jwt)
                setHelpMessage("Login success")
                navigate("/")
                window.location.reload()
            }
        }
    }, [data, navigate])
    const handleSubmit = e => {
        e.preventDefault()
        login({
            variables: {
                email: e.target.email.value,
                password: e.target.password.value
            }
        })
    }

    return (
        <form onSubmit={handleSubmit} className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Sign in to your account
            </h1>

            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                <input type="email" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="name@company.com" required />
            </div>
            <div>
                <label className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                <input type="password" name="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" required />
            </div>
            <input type="submit" className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center" value="Sign In" />

            {loading ? <Spinner /> : helpMessage}
        </form>
    )
}

export default LoginPage