import { useQuery } from '@apollo/client';
import { GET_ALL_TASKS } from '../../data/tasks';
import TaskListItem from "./TaskListItem";
import Spinner from '../common/Spinner';
import { getIdFromJwt } from '../../data/auth'
import { useNavigate } from "react-router-dom";
import Footer from '../common/Footer';

const TaskListPage = () => {
    const navigate = useNavigate();
    const ownerId = getIdFromJwt()

    const { data: open, loading: loadingOpen } = useQuery(GET_ALL_TASKS, {
        variables: {
            ownerId,
            isCompleted: false
        }
    })
    const { data: closed, loading: loadingClosed } = useQuery(GET_ALL_TASKS, {
        variables: {
            ownerId,
            isCompleted: true
        }
    })

    const isLoading = loadingOpen || loadingClosed

    const clearJwt = () => {
        localStorage.removeItem("jwt")
        window.location.reload()
    }

    const jwt = localStorage.getItem("jwt")
    if (jwt == null) {
        navigate("/login")
    }

    return (
        <>
            <div className="flex-1 overflow-y-auto">
                {isLoading ? <Spinner /> : (
                    <>
                        <div className='bg-slate-100 text-xs font-medium p-2 text-slate-500'>Open</div>
                        {open.tasks.map((task, index) => <TaskListItem key={index} task={task} />)}
                        <div className='bg-slate-100 text-xs font-medium p-2 text-slate-500'>Closed</div>
                        {closed.tasks.map((task, index) => <TaskListItem key={index} task={task} />)}
                    </>
                )}
                <button type="button" className='text-xs' onClick={clearJwt}>Logout</button>
            </div>
            <Footer>
                <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                    onClick={() => navigate("/new")}
                >
                    New Task
                </button>
            </Footer>

        </>
    )
}

export default TaskListPage