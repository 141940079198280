import jwt from 'jwt-decode'
import { gql } from "@apollo/client";

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
            jwt
        }
    }
`

export const getIdFromJwt = () => {
    const token = localStorage.getItem("jwt")
    if (token) {
        const decode = jwt(token)
        return decode.id
    }
}

