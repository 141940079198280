import { gql } from "@apollo/client";

export const GET_ALL_TASKS = gql`
    query Tasks($ownerId: ID!, $isCompleted: Boolean) {
        tasks(ownerId: $ownerId, isCompleted: $isCompleted) {
            id
            title
            isCompleted
            reminderTime
        }
    }
`

export const GET_TASK = gql`
    query Task($id: ID!) {
        task(id: $id) {
            id
            title
            description
            owner {
                name
            }
            isCompleted
            reminderTime
            createdTime
            updatedTime
        }
    }
`

export const CREATE_TASK = gql`
    mutation CreateTask($title: String!, $description: String, $reminderTime: Timestamp) {
        createTask(title: $title, description: $description, reminderTime: $reminderTime) {
            id
        }
    }
`

export const UPDATE_TASK = gql`
    mutation UpdateTask($id: ID!, $title: String, $description: String, $isCompleted: Boolean, $reminderTime: Timestamp) {
        updateTask(id: $id, title: $title, description: $description, isCompleted: $isCompleted, reminderTime: $reminderTime) {
            id
        }
    }
`

export const SET_REMINDER_TIME = gql`
    mutation SetReminderTime($id: ID!, $reminderTime: Timestamp) {
        setReminderTime(id: $id, reminderTime: $reminderTime) {
            id
        }
    }
`

export const SET_IS_COMPLETED = gql`
    mutation SetIsCompleted($id: ID!, $isCompleted: Boolean!) {
        setIsCompleted(id: $id, isCompleted: $isCompleted) {
            id
        }
    }
`