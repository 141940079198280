import TextareaAutosize from 'react-textarea-autosize';

const TaskViewDescriptionInput = ({ value, onChange, placeholder }) => {
  return (
    <TextareaAutosize 
        className='w-full' 
        placeholder={placeholder} 
        value={value}
        onChange={onChange}
    />
  );
};

export default TaskViewDescriptionInput;
