import { Link } from "react-router-dom";
import ReminderPill from "../pills/ReminderPill";

const TaskListItem = ({ task }) => (
    <Link
        to={task.id}
    >
        <div className={`border-b px-3 py-2 ${task.isCompleted && "opacity-50"}`}>
            <div>{task.title}</div>
            {!task.isCompleted && <ReminderPill time={task.reminderTime} /> }
            
        </div>
    </Link>
);

export default TaskListItem;
