import moment from "moment"
import { useEffect, useState } from "react"
import Pill from "../../common/Pill"

const TaskViewReminderInput = ({ value, onChange }) => {
    const [reminder, setReminder] = useState({
        date: "",
        time: "",
        isEditing: false
    })

    useEffect(() => {
        if (reminder.isEditing) {
            if (value) {
                setReminder(r => ({
                    ...r,
                    date: moment.unix(value).format("YYYY-MM-DD"),
                    time: moment.unix(value).format("HH:mm"),
                }))
            } else {
                setReminder(r => ({
                    ...r,
                    date: moment().format("YYYY-MM-DD"),
                    time: moment().format("HH:mm"),
                }))
            }
        }
    }, [reminder.isEditing, value])

    useEffect(() => {
        if (reminder.date !== "" && reminder.time !== "") {
            onChange(moment(`${reminder.date} ${reminder.time}`, "YYYY-MM-DD HH:mm").unix())
        }
    }, [reminder.date, reminder.time, onChange])

    const handleDateChange = (e) => {
        setReminder({ ...reminder, date: e.target.value })
    }
    const handleTimeChange = (e) => {
        setReminder({ ...reminder, time: e.target.value })
    }
    const enableEditing = () => setReminder({ ...reminder, isEditing: true })
    const clearReminder = () => {
        setReminder({
            date: "",
            time: "",
            isEditing: false
        })
        onChange(null)
    }

    if (reminder.isEditing) {
        return (
            <>
                <input 
                    type="date" 
                    className='mr-2' 
                    name="reminderDate" 
                    value={reminder.date} 
                    onChange={handleDateChange} 
                />
                <input 
                    type="time" 
                    className='mr-2' 
                    name="reminderTime" 
                    value={reminder.time} 
                    onChange={handleTimeChange} 
                />
                <button 
                    type="button" 
                    onClick={clearReminder}
                >
                    Clear
                </button>
            </>
        )
    } else {
        return (
            <Pill 
                bg='bg-gray-100' 
                text='text-gray-600' 
                onClick={enableEditing}
            >
                {value ? moment.unix(value).format("ddd, D MMM YYYY, h:mm a") : 'No reminders set'}
            </Pill>
        )
    }
}

export default TaskViewReminderInput